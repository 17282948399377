(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/jupiter/assets/javascripts/jupiter.js') >= 0) return;  svs.modules.push('/components/core/jupiter/assets/javascripts/jupiter.js');
"use strict";


svs = svs || {};
class HTTPError extends Error {
  constructor(obj) {
    super(obj.message);
    this.code = obj.code;
    this.reqId = obj.reqId;
  }
}


(function (svs) {
  'use strict';

  const apiUrl = svs.core.jupiter.data.url;
  svs.core.jupiter = function () {
    const logger = new svs.core.log.Logger('component:core.jupiter');
    let sessionTimeMs;
    let realityCheckMsg;
    if (svs && svs.core && svs.core.data) {
      sessionTimeMs = svs.core.data.sessionTimeMs;
      realityCheckMsg = svs.core.data.rcmsg;
      if (realityCheckMsg) {
        logger.debug("Found rcmsg from server with id: ".concat(realityCheckMsg.messageId));
      }
    }
    const sendRpp = path => {
      const requestCount = path.split('|').length;
      let isMultiFetch = false;
      if (path.indexOf('multifetch') > -1) {
        isMultiFetch = true;
      }
      svs.events.publish('/core/rpp', {
        requestCount,
        isMultiFetch
      });
    };
    return {
      parseErrorResponse(response) {
        let params;
        const jsonResp = response && response.responseJSON ? response.responseJSON : null;
        if (jsonResp && jsonResp.error) {
          if (jsonResp.error) {
            if (jsonResp.error.code) {
              params = {};
              params.code = jsonResp.error.code;
            }
            if (jsonResp.error.requestId) {
              params = params || {};
              params.reqId = jsonResp.error.requestId;
            }
            if (jsonResp.error.message) {
              params = params || {};
              params.message = decodeURI(jsonResp.error.message);
            }
          }
        } else if (jsonResp && jsonResp.status) {
          if (jsonResp.status.code) {
            params = {};
            params.code = jsonResp.status.code;
          }
          if (jsonResp.status.description) {
            params = params || {};
            params.description = jsonResp.status.description;
          }
          if (jsonResp.message) {
            params = params || {};
            params.message = jsonResp.message;
          }
        }
        return params;
      },
      url() {
        return apiUrl;
      },
      multiFetch(paths) {
        let serial = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        for (let i = 0; i < paths.length; i++) {
          paths[i] = encodeURIComponent(paths[i]);
        }
        return "".concat(this.url(), "/multifetch?serial=").concat(serial, "&urls=").concat(paths.join(encodeURIComponent('|')));
      },
      call(options, successCallback, errorCallback) {
        var _svs$core$observabili;
        const self = this;
        const {
          provider,
          inject,
          SpanKind,
          context: apiContext
        } = (_svs$core$observabili = svs.core.observability) !== null && _svs$core$observabili !== void 0 ? _svs$core$observabili : {};
        const tracer = provider === null || provider === void 0 ? void 0 : provider.getTracer();
        const spanMethod = (options.method || 'GET').toUpperCase();
        const span = tracer === null || tracer === void 0 ? void 0 : tracer.startSpan(spanMethod, {
          kind: SpanKind.CLIENT
        }, apiContext.active());
        span === null || span === void 0 || span.setAttribute('http.method', spanMethod);
        span === null || span === void 0 || span.setAttribute('http.url', options.path);
        span === null || span === void 0 || span.setAttribute('http.host', self.url());

        const doCall = function (options, successFunction, errorFunction) {
          const headers = inject ? inject({}, span) : {};
          const ajaxOpts = {
            type: options.method,
            url: self.url() + options.path,
            dataType: 'json',
            data: options.data,
            cache: false,
            timeout: svs.core.config.data.svsconfig.clientAjaxTimeout,
            xhrFields: {
              withCredentials: true
            },
            success: successFunction,
            error: errorFunction,
            context: options.context,
            headers
          };
          sendRpp(options.path);
          if (options.ghost) {
            ajaxOpts.headers = ajaxOpts.headers || [];
            ajaxOpts.headers['X-Casper'] = 'true';
          } else {
            ajaxOpts.complete = function (xhr, status) {
              if (xhr && xhr.getResponseHeader('x-casper')) {
                setTimeout(haunt, 0, options);
              }
              if (options.complete) {
                options.complete(xhr, status);
              }
            };
          }
          if (options.method.toLowerCase() !== 'get') {
            if (options.data instanceof FormData) {
              ajaxOpts.contentType = false;
            } else {
              ajaxOpts.contentType = 'application/json';
              if (typeof options.data !== 'string') {
                ajaxOpts.data = JSON.stringify(options.data);
              }
            }
            ajaxOpts.processData = false;
            ajaxOpts.crossDomain = true;
          } else {
            ajaxOpts.contentType = 'text/plain';
          }
          return $.ajax(ajaxOpts);
        };

        const onSuccess = function (response, status, xhr) {
          logger.debug("Jupiter call success ".concat(options.method, " ").concat(options.path));
          span === null || span === void 0 || span.setAttribute('http.status_code', xhr.status);
          span === null || span === void 0 || span.end();
          svs.events.publish('/core/jupiter/response', xhr.status);
          response = response || {};
          if (response.responses) {
            sessionTimeMs = response.responses[0].requestInfo && response.responses[0].requestInfo.sessionTimeMs;
          } else {
            sessionTimeMs = response.requestInfo && response.requestInfo.sessionTimeMs;
          }
          realityCheckMsg = response.rcmsg ? response.rcmsg : undefined;
          if (sessionTimeMs) {
            svs.events.publish('/core/jupiter/sessionTimeMs', sessionTimeMs);
          }
          if (xhr.getResponseHeader('X-RC-Messageid') || xhr.getResponseHeader('X-RC-Payload')) {
            logger.debug("Received rcmsg with id: ".concat(xhr.getResponseHeader('X-RC-Messageid')));
            realityCheckMsg = {
              messageId: xhr.getResponseHeader('X-RC-Messageid'),
              payload: xhr.getResponseHeader('X-RC-Payload')
            };
            svs.events.publish('/componets/realitycheck/message', realityCheckMsg);
          }
          if (successCallback) {
            response = JSON.parse(JSON.stringify(response), (key, value) => {
              if (key === 'sessionId' || key === 'deviceId') {
                return '[hidden]';
              }
              return value;
            });
            successCallback.call(options.context, response, status, xhr);
          }
        };

        const onError = function (xhr, status, err) {
          let errorCode;
          if (xhr.status === 0 && status === 'timeout') {
            xhr.status = 408;
            errorCode = 408;
          } else if (xhr.status > 0 && xhr.status < 599) {
            errorCode = xhr.status;
          } else {
            errorCode = 666;
          }
          span === null || span === void 0 || span.setAttribute('error', true);
          span === null || span === void 0 || span.setAttribute('http.status_code', xhr.status);
          span === null || span === void 0 || span.addEvent("Error http status was ".concat(xhr.status));
          span === null || span === void 0 || span.end();
          let logMsg = "Jupiter call failed ".concat(xhr.status, " (").concat(options.method, " ").concat(options.path, ")");
          if (!xhr.responseJSON) {
            if (err === '' && status === 'error' && xhr.status === 0) {
              logMsg += ', Potential network error';
            } else {
              logMsg += ", Message:".concat(err);
            }
            logMsg += ", Status:".concat(status, ", XhrStatus:").concat(xhr.status, ", XhrText:").concat(xhr.responseText);
            if (xhr.status >= 300 && xhr.status < 500) {
              logger.info(logMsg);
            } else {
              logger.error(logMsg);
            }
            const customErrorObject = {
              error: {
                code: errorCode,
                message: "Trinidad Error : ".concat(err)
              }
            };
            xhr.responseJSON = customErrorObject;
            xhr.responseText = JSON.stringify(customErrorObject);
          } else if (xhr.status >= 300 && xhr.status < 500) {
            logger.info("".concat(logMsg, ", Message:").concat(err), self.parseErrorResponse(xhr));
          } else {
            logger.warn("".concat(logMsg, ", Message:").concat(err), self.parseErrorResponse(xhr));
          }
          svs.events.publish('/core/jupiter/response', xhr.status);
          if (errorCallback) {
            errorCallback.call(options.context, xhr, status, err);
          }
        };

        const onErrorExt = function (xhr, status, err) {
          let errCodeStr;
          let errCode;
          switch (xhr.status) {
            case 400:
              if (xhr.responseJSON && xhr.responseJSON.error) {
                errCodeStr = String(xhr.responseJSON.error.code);
                errCode = parseInt(errCodeStr.replace(/\s/g, ''), 10);
                switch (errCode) {
                  case 3107:
                    logger.info("Client tries to access protected path unauthorized in ASM, path: ".concat(options.path));
                    svs.core.userSession.logout(svs.core.userSession.logoutReason.INVALID_SESSION);
                    break;
                  case 100005:
                    logger.info("Client tries to access protected path unauthorized, path: ".concat(options.path));
                    break;
                  default:
                    onError(xhr, status, err);
                }
              } else {
                onError(xhr, status, err);
              }
              break;
            case 401:
              logger.info("Client has invalid session, path: ".concat(options.path));
              svs.core.userSession.logout(svs.core.userSession.logoutReason.INVALID_SESSION);
              break;
            case 403:
              logger.info("Client tries to access protected path unauthorized, path: ".concat(options.path));
              onError(xhr, status, err);
              break;
            case 500:
              if (xhr.responseJSON && xhr.responseJSON.error) {
                errCodeStr = String(xhr.responseJSON.error.code);
                errCode = parseInt(errCodeStr.replace(/\s/g, ''), 10);
                switch (errCode) {
                  case 3107:
                    logger.info("Client tries to access protected path unauthorized in ASM, path: ".concat(options.path));
                    svs.core.userSession.logout(svs.core.userSession.logoutReason.INVALID_SESSION);
                    break;
                  case 100005:
                    logger.info("Client tries to access protected path unauthorized ASM, path: ".concat(options.path));
                    break;
                  default:
                    onError(xhr, status, err);
                }
              } else {
                onError(xhr, status, err);
              }
              break;
            default:
              onError(xhr, status, err);
          }
        };

        const haunt = function (options) {
          options.ghost = true;
          doCall(options, (response, status) => {
            logger.debug("Received Ghost response. Status: ".concat(status, ". Uri: ").concat(options.method, " ").concat(options.path));
          }, (xhr, status) => {
            logger.warn("Received Ghost response with error. Status: ".concat(status, ". Uri: ").concat(options.method, " ").concat(options.path));
          });
        };

        if (!Object.prototype.hasOwnProperty.call(options, 'useExtErrFunc')) {
          options.useExtErrFunc = true;
        }
        if (options.useExtErrFunc) {
          return doCall(options, onSuccess, onErrorExt);
        }
        return doCall(options, onSuccess, onError);
      },
      callParallel(paths, success, error, limit, executeError) {
        const self = this;
        const responses = [];
        const localPaths = paths || [];
        const noop = function () {};
        success = success || noop;
        error = error || noop;
        executeError = executeError !== false; 

        limit = limit || 10; 

        if (!localPaths.length || typeof success !== 'function' || typeof error !== 'function') {
          logger.debug('Invalid parameters.', localPaths, success, error);
          return;
        }
        this.eachLimit(localPaths, limit, (path, callback) => {
          self.call({
            method: 'GET',
            path
          }, response => {
            responses.push({
              path,
              data: response
            });
            callback();
          }, (xhr, status, err) => {
            if (executeError) {
              callback(xhr, status, err);
            } else {
              callback();
            }
          });
        }, (xhr, status, err) => {
          if (executeError && xhr && xhr.responseJSON && xhr.responseJSON.error) {
            error(xhr, status, err);
          } else {
            success(responses);
          }
        });
      },
      eachLimit(arr, limit, iterator, callback) {
        callback = callback || function () {};
        if (!arr.length || limit <= 0) {
          return callback();
        }
        let completed = 0;
        let started = 0;
        let running = 0;
        (function replenish() {
          if (completed >= arr.length) {
            return callback();
          }
          function iteratorFn(xhr, status, err) {
            if (err) {
              callback(xhr, status, err);
              callback = function () {};
            } else {
              completed += 1;
              running -= 1;
              if (completed >= arr.length) {
                callback();
              } else {
                replenish();
              }
            }
          }
          while (running <= limit && started < arr.length) {
            started += 1;
            running += 1;
            iterator(arr[started - 1], iteratorFn);
          }
        })();
      },
      getSessionTimeMs() {
        return sessionTimeMs;
      },
      getRealityCheckMsg() {
        return realityCheckMsg;
      },
      callAsync(options) {
        return new Promise((resolve, reject) => {
          this.call(options, (response, status, xhr) => {
            resolve({
              response,
              status,
              xhr
            });
          }, xhr => {
            const errObj = this.parseErrorResponse(xhr);
            reject(new HTTPError(errObj));
          });
        });
      }
    };
  }();
  ['get', 'post', 'delete', 'put'].forEach(method => {
    svs.core.jupiter[method] = function (options, successCallback, errorCallback) {
      if (typeof options === 'object') {
        options.method = method;
      }

      if (typeof options === 'string') {
        options = {
          path: options,
          method
        };
      } else if (method === 'get' && Array.isArray(options)) {
        options = {
          path: "/multifetch?urls=".concat(options.join('|')),
          method
        };
      }
      return this.call(options, successCallback, errorCallback);
    };
  });
})(svs);

 })(window);